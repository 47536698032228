import "zod-openapi/extend";

import { z } from "zod";
import { ActionIdPlaceholderSchema } from "./base";
import { URL_PATTERN_REGEX } from "utils/regex";

// Block actions
export const NavigateActionSchema = z.object({
  type: z.literal("navigate"),
  config: z.object({
    id: z.string().cuid().or(ActionIdPlaceholderSchema).optional(),
    pageId: z.string(),
    // TODO: remove this once the template engine no longer uses it
    pageName: z.string().optional(),
    sendQueryParams: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),
  }),
});
export type NavigateAction = z.infer<typeof NavigateActionSchema>;

export const NotificationActionSchema = z.object({
  type: z.literal("notification"),
  config: z.object({
    id: z.string().cuid().or(ActionIdPlaceholderSchema).optional(),
    message: z.string(),
    position: z
      .union([
        z.literal("top-left"),
        z.literal("top-center"),
        z.literal("top-right"),
        z.literal("bottom-left"),
        z.literal("bottom-center"),
        z.literal("bottom-right"),
      ])
      .optional(),
  }),
});

export type NotificationAction = z.infer<typeof NotificationActionSchema>;

export const OpenExternalUrlActionSchema = z.object({
  type: z.literal("openExternalUrl"),
  config: z.object({
    id: z.string().cuid().or(ActionIdPlaceholderSchema).optional(),
    url: z.string().regex(URL_PATTERN_REGEX),
    sendQueryParams: z
      .boolean()
      .default(false)
      .openapi({ effectType: "input" }),
    opensInNewTab: z.boolean().default(true).openapi({ effectType: "input" }),
  }),
});

export type OpenExternalUrlAction = z.infer<typeof OpenExternalUrlActionSchema>;
